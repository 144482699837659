<template>
  <div class="fixed-header"><img
      src="@/assets/totogi-logo-white.svg"
      alt="Totogi Logo"
      style=""
  /></div>
  <div class="container"><vimeo-player ref="player" :video-id="vidid" :options="options" @ready="onReady"/></div>
  <div class="fixed-footer">&copy; {{currentDate.getFullYear()}} Totogi, All Rights Reserved </div>
</template>

<script>
export default {
  data() {
    return {
      currentDate: new Date,
      vidid: '520074683',
      options: {
        muted: false,
        height: 1080,
        autoplay: false,
        responsive: true,
        quality: '1080p'
      },
      playerReady: false
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.vidid = urlParams.get("vidid");
  },
  methods: {
    onReady() {
      this.playerReady = true
    },
    play () {
      this.$refs.player.play()
    },
    pause () {
      this.$refs.player.pause()
    }
  }
}
</script>
<style>
/* Add some padding on document's body to prevent the content
to go underneath the header and footer */
html, body {
  height:100%;
  width:100%;
  padding:0;
  margin:0;
  background: #fff3eb;
}
.fixed-header, .fixed-footer{
  width: 100%;
  padding: 0px 0;
}
.fixed-header{
  top: 0;
  background: #001d3d;
  padding: 10px 10px;
  height: 50px;
}
.fixed-footer{
  bottom: 0;
  height: 50px;
  background: #fff3eb;
  color:#979aaa;
  text-align: center;
  font-size: 1.2em;
}
.container{
  width: 80%;
  margin: 20px auto; /* Center the DIV horizontally */
  background: #fff3eb;
}
</style>




